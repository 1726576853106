import React from 'react';
import { graphql } from 'gatsby';
import { useForm, ValidationError } from '@formspree/react';

import LayoutSimple from '../components/layout-simple';
import Seo from '../components/seo';

const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const [state, handleSubmit] = useForm("mzbykyrq");

  return (
    <LayoutSimple location={location} title={siteTitle}>
      <Seo title="Contact Us" />
      <div className="row justify-content-center">
        <div className="col-8">

          <article className="post">
            <h2 className="text-center mt-0 mb-5 pb-3 simple-page-title"><span>Get In Touch</span></h2>
            <p className="text-center">Duis mollis eget augue et lobortis. Nam nunc justo, aliquet sed condimentum vel, faucibus in dolor. Proin eleifend hendrerit rhoncus.</p>

            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input id="name" className="form-control" placeholder="Name*" name="name" type="text" required />
                  </div>
                  <ValidationError prefix="Name" field="name" errors={state.errors} />
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <input id="email" className="form-control" placeholder="Email*" name="email" type="email" required />
                  </div>
                  <ValidationError prefix="Email" field="email" errors={state.errors} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <input id="subject" className="form-control" placeholder="Subject" name="subject" type="text" />
                  </div>
                  <ValidationError prefix="Subject" field="subject" errors={state.errors} />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea id="message" className="form-control" rows="5" placeholder="Your Message*" name="message" required></textarea>
                  </div>
                  <ValidationError prefix="Message" field="message" errors={state.errors} />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group text-right">
                    <button name="submit" type="submit" id="submit" className="btn btn-dark" disabled={state.submitting}>Send</button>
                  </div>
                </div>
              </div>
            </form>
            {state.succeeded && <div class="alert alert-success text-center" role="alert">Thanks for contacting us. We will get in touch with you shortly.</div>}
          </article>
        </div>
      </div>
    </LayoutSimple>
  )
}

export default Contact;

export const pageQuery = graphql`
          query {
            site {
            siteMetadata {
            title
          }
    }
  }
          `
